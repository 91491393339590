<template>
  <div class="containerx">
    <!-- 添加项目dialog -->
    <el-dialog width="30%" title="新增公司" :visible.sync="show0">
      <el-form :model="add_p_obj" label-width="100px">
        <el-form-item label="公司名称">
          <el-input v-model="add_p_obj.projectName"></el-input>
        </el-form-item>
        <el-form-item label="负 责 人">
          <el-input v-model="add_p_obj.director"></el-input>
        </el-form-item>
        <el-form-item label="公司地址">
          <el-input v-model="add_p_obj.address"></el-input>
        </el-form-item>
        <el-form-item label="公司状态">
          <el-radio-group v-model="add_p_obj.state">
            <el-radio :label="0">进行中</el-radio>
            <el-radio :label="1">已完成</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="show0 = false">取 消</el-button>
        <el-button type="primary" @click="confirmAddP">确 定</el-button>
      </div>
    </el-dialog>
    <el-dialog width="30%" title="编辑" :visible.sync="edit0_show">
      <el-form :model="edit_p_obj0" label-width="100px">
        <el-form-item label="公司名称">
          <el-input v-model="edit_p_obj0.projectName"></el-input>
        </el-form-item>
        <el-form-item label="负 责 人">
          <el-input v-model="edit_p_obj0.director"></el-input>
        </el-form-item>
        <el-form-item label="公司地址">
          <el-input v-model="edit_p_obj0.address"></el-input>
        </el-form-item>
        <el-form-item label="公司状态">
          <el-radio-group v-model="edit_p_obj0.state">
            <el-radio :label="0">进行中</el-radio>
            <el-radio :label="1">已完成</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="edit0_show = false">取 消</el-button>
        <el-button type="primary" @click="confirmEdit0">确 定</el-button>
      </div>
    </el-dialog>
    <!-- 1111 -->
    <el-dialog width="30%" title="编辑" :visible.sync="edit1_show">
      <el-input v-model="edit1_input"></el-input>
      <div slot="footer" class="dialog-footer">
        <el-button @click="edit1_show = false">取 消</el-button>
        <el-button type="primary" @click="confirmEdit1">确 定</el-button>
      </div>
    </el-dialog>
    <el-dialog width="30%" title="新增" :visible.sync="add1_show">
      <el-input v-model="add1_input"></el-input>
      <div slot="footer" class="dialog-footer">
        <el-button @click="add1_show = false">取 消</el-button>
        <el-button type="primary" @click="confirmAdd1">确 定</el-button>
      </div>
    </el-dialog>
    <!-- 2222 -->
    <el-dialog width="30%" title="编辑" :visible.sync="edit2_show">
      <el-input v-model="edit2_input"></el-input>
      <div slot="footer" class="dialog-footer">
        <el-button @click="edit2_show = false">取 消</el-button>
        <el-button type="primary" @click="confirmEdit2">确 定</el-button>
      </div>
    </el-dialog>
    <el-dialog width="30%" title="新增" :visible.sync="add2_show">
      <el-input v-model="add2_input"></el-input>
      <div slot="footer" class="dialog-footer">
        <el-button @click="add2_show = false">取 消</el-button>
        <el-button type="primary" @click="confirmAdd2">确 定</el-button>
      </div>
    </el-dialog>
    <!-- 3333 -->
    <el-dialog width="30%" title="编辑" :visible.sync="edit3_show">
      <el-input v-model="edit3_input"></el-input>
      <div slot="footer" class="dialog-footer">
        <el-button @click="edit3_show = false">取 消</el-button>
        <el-button type="primary" @click="confirmEdit3">确 定</el-button>
      </div>
    </el-dialog>
    <el-dialog width="30%" title="新增" :visible.sync="add3_show">
      <el-input v-model="add3_input"></el-input>
      <div slot="footer" class="dialog-footer">
        <el-button @click="add3_show = false">取 消</el-button>
        <el-button type="primary" @click="confirmAdd3">确 定</el-button>
      </div>
    </el-dialog>


    <div v-if="project==0" class="project0">
      <div style="width: 100%;display: flex;justify-content: space-between;align-items: center;">
        <div class="title">公司列表</div>
        <div style="display: flex;">
          <div @click="addProject" class="add">新增</div>

        </div>
      </div>
      <div class="table">
        <div class="tr" style="background: linear-gradient(0deg, rgba(43, 201, 222, 0.6), rgba(43, 201, 222, 0.6));">
          <div class="td1" style="padding: 0;">
            <p>序号</p>
          </div>
          <div class="td2 td" style="padding: 0;">
            <p style="text-indent: 2rem;">公司名称</p>
          </div>
          <div class="td22 td" style="padding: 0;">
            <p style="text-indent: 2rem;">负责人</p>
          </div>
          <div class="td3s td" style="padding: 0;">
            <div class="tdd2" style="position: relative;">
              <el-switch v-model="mySwitch" @change="mySwitchChange" active-color="rgba(43, 201, 222, 1)"
                inactive-color="#ff4949">
              </el-switch>
              <p style="margin-right: 2rem;position: absolute;left: -6rem;top: 50%;transform: translateY(-50%);">是否在大屏展示
              </p>
            </div>
          </div>
        </div>
        <div class="tr" v-for="(item, index) in tableData0" :class="index % 2 == 0?'bg1':'bg2'">
          <div class="td1">
            <p>{{ index+1 }}</p>
          </div>
          <div class="td2 td" @click="handleTb0(item)">
            <p>{{item.projectName}}</p>
          </div>
          <div class="td22 td" >
            <p>{{item.director}}</p>
          </div>
          <div class="td3s td" style="padding-left: 0; justify-content: center;">
            <div class="tdd2" style="color: rgba(43, 201, 222, 1);cursor: pointer;">
              <el-switch @change="switchChange($event, item)" v-model="item.isShow==1?true:false"
                active-color="rgba(43, 201, 222, 1)" inactive-color="#ff4949">
              </el-switch>
            </div>
            <div @click="edit0(item)" class="tdd2" style="color: rgba(43, 201, 222, 1);cursor: pointer;">编辑</div>
            <div @click="delete0(item,0)" class="tdd2"
              style="margin-right: 0;color: rgba(250, 105, 105, 1);cursor: pointer;">删除</div>
          </div>
        </div>
      </div>
      <div class="pagination">
        <el-pagination style="color: white" background @current-change="currentChange0" @size-change="sizeChange0"
          :current-page.sync="current0" :page-sizes="[10, 15, 20]" layout="total, sizes, prev, pager, next, jumper"
          :total="total0">
        </el-pagination>
      </div>
    </div>
    <div v-if="project==1" class="project0">
      <div style="width: 100%;display: flex;justify-content: space-between;align-items: center;">
        <div class="title">
          <span @click="project=0" style="color: rgba(43, 222, 201, 0.4);cursor: pointer;">公司列表&nbsp; / &nbsp;</span>
          <span style="color: rgba(43, 201, 222, 1);cursor: pointer;">{{xmcj1}}</span>
        </div>
        <div style="display: flex;">
          <div @click="addLevel1" class="add">新增</div>
          <div @click="project=0" class="back">返回</div>
        </div>
      </div>
      <div class="table">
        <div class="tr" style="background: linear-gradient(0deg, rgba(43, 201, 222, 0.6), rgba(43, 201, 222, 0.6));">
          <div class="td1">
            <p>序号</p>
          </div>
          <div class="td" style="
            width: 83.4rem;">
            <p>项目名称</p>
          </div>
          <!-- <div class="td" style="
            width: 20rem;">
            <p>负责人</p>
          </div> -->
          <div class="td3s td" style="justify-content: center;">
            <p style="margin-right: 2rem;">操作</p>
          </div>
        </div>
        <div class="tr" v-for="(item, index) in tableData1" :class="index % 2 == 0?'bg':'bg2'">
          <div class="td1">
            <p>{{ index+1 }}</p>
          </div>
          <div @click="handleTb1(item)" class="td" style="cursor: pointer;width: 83.4rem;">
            <p>{{item.levelName}}</p>
          </div>
          <!-- <div class="td" style="width: 20rem;">
            <p>{{item.director}}</p>
          </div> -->
          <div class="td3s td" style="display: flex;padding-left: 0;">
            <div @click="edit1(item)" class="tdd" style="color: rgba(43, 201, 222, 1);cursor: pointer;">编辑</div>
            <div @click="delete1(item,1)" class="tdd"
              style="margin-right: 0;color: rgba(250, 105, 105, 1);cursor: pointer;">删除</div>
          </div>
        </div>
      </div>
      <div class="pagination">
        <el-pagination style="color: white" background @current-change="currentChange1" @size-change="sizeChange1"
          :current-page.sync="current1" :page-sizes="[10, 15, 20]" layout="total, sizes, prev, pager, next, jumper"
          :total="total1">
        </el-pagination>
      </div>
    </div>
    <div v-if="project==2" class="project0">
      <div style="width: 100%;display: flex;justify-content: space-between;align-items: center;">
        <div class="title">
          <span @click="project=0" style="color: rgba(43, 222, 201, 0.4);cursor: pointer;">公司列表&nbsp; / &nbsp;</span>
          <span @click="project=1" style="color: rgba(43, 222, 201, 0.4);cursor: pointer;">{{xmcj1}}&nbsp; /
            &nbsp;</span>
          <span style="color: rgba(43, 201, 222, 1);cursor: pointer;">{{xmcj2}}</span>
        </div>
        <div style="display: flex;">
          <div @click="addLevel2" class="add">新增</div>
          <div @click="project=1" class="back">返回</div>
        </div>
      </div>
      <div class="table">
        <div class="tr" style="background: linear-gradient(0deg, rgba(43, 201, 222, 0.6), rgba(43, 201, 222, 0.6));">
          <div class="td1">
            <p>序号</p>
          </div>
          <div class="td" style="
            width: 83.4rem;">
            <p>项目名称</p>
          </div>
          <!-- <div class="td" style="
            width: 20rem;">
            <p>负责人</p>
          </div> -->
          <div class="td3s td" style="justify-content: center;">
            <p style="margin-right: 2rem;tex">操作</p>
          </div>
        </div>
        <div class="tr" v-for="(item, index) in tableData2" :class="index % 2 == 0?'bg1':'bg2'">
          <div class="td1">
            <p>{{ index+1 }}</p>
          </div>
          <div @click="handleTb2(item)" class="td" style="cursor: pointer;width: 83.4rem;">
            <p>{{item.levelName}}</p>
          </div>
          <!-- <div class="td" style="width: 20rem;">
            <p>{{item.director}}</p>
          </div> -->
          <div class="td3s td" style="display: flex;padding-left: 0;">
            <div @click="edit2(item)" class="tdd" style="color: rgba(43, 201, 222, 1);cursor: pointer;">编辑</div>
            <div @click="delete2(item,2)" class="tdd"
              style="margin-right: 0;color: rgba(250, 105, 105, 1);cursor: pointer;">删除</div>
          </div>
        </div>
      </div>
      <div class="pagination">
        <el-pagination style="color: white" background @current-change="currentChange2" @size-change="sizeChange2"
          :current-page.sync="current2" :page-sizes="[10, 15, 20]" layout="total, sizes, prev, pager, next, jumper"
          :total="total2">
        </el-pagination>
      </div>
    </div>
    <div v-if="project==3" class="project0">
      <div style="width: 100%;display: flex;justify-content: space-between;align-items: center;">
        <div class="title">
          <span @click="project=0" style="color: rgba(43, 222, 201, 0.4);cursor: pointer;">公司列表&nbsp; / &nbsp;</span>
          <span @click="project=1" style="color: rgba(43, 222, 201, 0.4);cursor: pointer;">{{xmcj1}}&nbsp; /
            &nbsp;</span>
          <span @click="project=2" style="color: rgba(43, 222, 201, 0.4);cursor: pointer;">{{xmcj2}}&nbsp; /
            &nbsp;</span>
          <span style="color: rgba(43, 201, 222, 1);cursor: pointer;">{{xmcj3}}</span>
        </div>
        <div style="display: flex;">
          <div @click="addLevel3" class="add">新增</div>
          <div @click="project=2" class="back">返回</div>
        </div>
      </div>
      <div class="table">
        <div class="tr" style="background: linear-gradient(0deg, rgba(43, 201, 222, 0.6), rgba(43, 201, 222, 0.6));">
          <div class="td1">
            <p>序号</p>
          </div>
          <div class="td" style="
            width: 83.4rem;">
            <p>项目名称</p>
          </div>
          <!-- <div class="td" style="
            width: 20rem;">
            <p>负责人</p>
          </div> -->
          <div class="td3s td" style="justify-content: center;">
            <p style="margin-right: 2rem;">操作</p>
          </div>
        </div>
        <div class="tr" v-for="(item, index) in tableData3" :class="index % 2 == 0?'bg1':'bg2'">
          <div class="td1">
            <p>{{ index+1 }}</p>
          </div>
          <div @click="handleTb3(item)" class="td" style="cursor: pointer;width: 83.4rem;">
            <p>{{item.levelName}}</p>
          </div>
          <!-- <div class="td" style="width: 20rem;">
            <p>{{item.director}}</p>
          </div> -->
          <div class="td3s td" style="display: flex;padding-left: 0;">
            <div @click="edit3(item)" class="tdd" style="color: rgba(43, 201, 222, 1);cursor: pointer;">编辑</div>
            <div @click="delete3(item,3)" class="tdd"
              style="margin-right: 0;color: rgba(250, 105, 105, 1);cursor: pointer;">删除</div>
          </div>
        </div>
      </div>
      <div class="pagination">
        <el-pagination style="color: white" background @current-change="currentChange3" @size-change="sizeChange3"
          :current-page.sync="current3" :page-sizes="[10, 15, 20]" layout="total, sizes, prev, pager, next, jumper"
          :total="total3">
        </el-pagination>
      </div>
    </div>


    <el-dialog title="确认删除？" :visible.sync="dialogFormVisible">
      <span style="margin-left: 1rem; font-size: 4rem; font-weight: 700;">手机号：{{form.phone}}</span>
      <span style="margin-left: 24rem;">
        <el-button @click="Yzm()" :disabled="An" type="primary" round>{{codename}}</el-button>
      </span>
      <el-form :model="form" style="margin-top: 2rem;">
        <el-form-item label="验证码" :label-width="formLabelWidth">
          <el-input v-model="form.code" autocomplete="off"></el-input>
        </el-form-item>
      </el-form>
      <el-button @click="Gh" type="primary" round>更换手机号</el-button>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="del1()">确 定</el-button>
      </div>
    </el-dialog>


    <el-dialog title="更换手机号" :visible.sync="dialogFormVisible1">
      <span style="margin-left: 1rem; font-size: 4rem; font-weight: 700;">旧手机号：{{form1.oldPhone}}</span>

      <el-form :model="form1" style="margin-top: 2rem;">
        <el-form-item style="position: relative;" label="旧验证码" :label-width="formLabelWidth">
          <el-input v-model="form1.oldCode" autocomplete="off"></el-input>
          <el-button style="position: absolute;top: 50%; transform: translateY(-50%); right: .5rem;" @click="Yzm()"
            :disabled="An" type="primary" round size="mini">{{codename}}</el-button>
        </el-form-item>
        <el-form-item style="position: relative;" label="新手机" :label-width="formLabelWidth">
          <el-input v-model="form1.newPhone" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item style="position: relative;" label="新验证码" :label-width="formLabelWidth">
          <el-input v-model="form1.newCode" autocomplete="off"></el-input>
          <el-button style="position: absolute;top: 50%; transform: translateY(-50%); right: .5rem;" @click="Yzm1()"
            :disabled="An1" type="primary" round size="mini">{{codename1}}</el-button>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible1 = false">取 消</el-button>
        <el-button type="primary" @click="ghhm()">确 定</el-button>
      </div>
    </el-dialog>
  </div>


  </div>
</template>

<script>
  export default {
    data() {
      return {
        dialogFormVisible: false,
        project: 0,
        add_p_obj: {
          address: '',
          director:'',
          projectName: "",
          state: 0
        },
        tableData0: [],
        current0: 1,
        size0: 10,
        total0: 0,
        show0: false,
        edit0_show: false,
        edit_p_obj0: {
          address: '',
          director:'',
          projectName: "",
          state: 0
        },
        // 1111111
        projectId1: null,
        tableData1: [],
        current1: 1,
        size1: 10,
        total1: 0,
        show1: false,
        edit1_show: false,
        edit1_input: '',
        cj_id1: null,
        add1_show: false,
        add1_input: '',
        // 2222222
        projectId2: null,
        tableData2: [],
        current2: 1,
        size2: 10,
        total2: 0,
        show2: false,
        edit2_show: false,
        edit2_input: '',
        cj_id2: null,
        add2_show: false,
        add2_input: '',
        // 33333333
        projectId3: null,
        tableData3: [],
        current3: 1,
        size3: 10,
        total3: 0,
        show3: false,
        edit3_show: false,
        edit3_input: '',
        cj_id3: null,
        add3_show: false,
        add3_input: '',
        projectId_1: null,
        projectId_2: null,
        formLabelWidth: '12rem',
        form: {
          phone: '',
          code: ''
        },
        form1: {
          "newCode": '',
          "newPhone": '',
          "oldCode": '',
          "oldPhone": ''
        },
        codename: '获取验证码',
        An: false,
        timer: null,
        codename1: '获取验证码',
        An1: false,
        timer1: null,
        delid: null,
        job: null,
        dialogFormVisible1: false,
        xmcj1: '项目层级一',
        xmcj2: '项目层级二',
        xmcj3: '项目层级三',
        // 总开关
        mySwitch: false,
      }
    },
    mounted() {
      this.getPage()
    },
    methods: {

      mySwitchChange(e) {
        console.log(e)
        var obj = {
          id: null,
          isShow: e ? 1 : 0
        }
        this.$newPost('/project/switch', obj).then(res => {
          if (res.code == 2000) {
            this.$message({
              message: res.message,
              type: 'success'
            })
            this.getPage()
          } else {
            this.$message({
              message: res.message,
              type: 'error'
            })
          }
        })
      },
      switchChange(e, item) {
        console.log(e, item)
        var obj = {
          id: item.id,
          isShow: e ? 1 : 0
        }
        this.$newPost('/project/switch', obj).then(res => {
          if (res.code == 2000) {
            this.$message({
              message: res.message,
              type: 'success'
            })
            this.getPage()
          } else {
            this.$message({
              message: res.message,
              type: 'error'
            })
          }
        })
      },
      ghhm() {
        this.$newPost('/phone/update', this.form1).then(res => {
          console.log(res, 'xxx')
          if (res.code == 2000) {
            this.$message({
              message: res.message,
              type: 'success'
            })
            this.dialogFormVisible1 = false
          } else {
            this.$message({
              message: res.message,
              type: 'error'
            })
          }
        })
      },
      Gh() {
        this.dialogFormVisible = false
        this.dialogFormVisible1 = true
        this.form1 = {
          "newCode": '',
          "newPhone": '',
          "oldCode": '',
          "oldPhone": this.form.phone
        }
      },
      del1() {
        let obj = {
          "code": this.form.code,
          "phone": this.form.phone
        }
        if (this.job == 0) {
          this.$ajax('yzsj', 'post', obj).then(res => {
            console.log(res);
            if (res.code == 2000) {
              this.$newDelete('/project/delete/' + this.delid).then(res => {
                console.log(res, 'xxx')
                if (res.code == 2000) {
                  this.$message({
                    type: 'success',
                    message: '删除成功!'
                  });
                  this.edit0_show = false
                  this.getPage()
                  this.dialogFormVisible = false
                } else {
                  this.$message({
                    message: res.message,
                    type: 'error'
                  })
                  this.edit0_show = false
                }
              })
            } else {
              this.$message(res.message);
            }
          })
        } else if (this.job == 1) {
          this.$ajax('yzsj', 'post', obj).then(res => {
            console.log(res);
            if (res.code == 2000) {
              this.$newDelete('/level/delete/' + this.delid).then(res => {
                console.log(res, 'xxx')
                if (res.code == 2000) {
                  this.$message({
                    type: 'success',
                    message: '删除成功!'
                  });
                  this.edit1_show = false
                  this.getLevel1()
                  this.dialogFormVisible = false
                } else {
                  this.$message({
                    message: res.message,
                    type: 'error'
                  })
                  this.edit1_show = false
                }
              })
            } else {
              this.$message(res.message);
            }
          })

        } else if (this.job == 2) {
          this.$ajax('yzsj', 'post', obj).then(res => {
            console.log(res);
            if (res.code == 2000) {
              this.$newDelete('/level/delete/' + this.delid).then(res => {
                console.log(res, 'xxx')
                if (res.code == 2000) {
                  this.$message({
                    type: 'success',
                    message: '删除成功!'
                  });
                  this.edit1_show = false
                  this.getLevel1()
                  this.dialogFormVisible = false
                } else {
                  this.$message({
                    message: res.message,
                    type: 'error'
                  })
                  this.edit1_show = false
                }
              })
            } else {
              this.$message(res.message);
            }
          })
        } else if (this.job == 3) {
          this.$ajax('yzsj', 'post', obj).then(res => {
            console.log(res);
            if (res.code == 2000) {
              this.$newDelete('/level/delete/' + this.delid).then(res => {
                console.log(res, 'xxx')
                if (res.code == 2000) {
                  this.$message({
                    type: 'success',
                    message: '删除成功!'
                  });
                  this.edit1_show = false
                  this.getLevel1()
                  this.dialogFormVisible = false
                } else {
                  this.$message({
                    message: res.message,
                    type: 'error'
                  })
                  this.edit1_show = false
                }
              })
            } else {
              this.$message(res.message);
            }
          })
        }

        this.form.code = ''
        clearInterval(this.timer);
      },
      Yzm() {
        let t1 = 60
        let obj = {
          "phone": this.form.phone
        }
        this.$ajax('code', 'post', obj).then(res => {
          console.log(res);
          if (res.code == 2000) {
            this.An = true
            this.timer = setInterval(() => {
              if (t1 > 0) {
                this.codename = t1 + "s后重新获取"
                t1--;
                console.log(t1);
              } else {
                this.codename = '重新获取'
                this.An = false;
                clearInterval(this.timer);
                this.timer = null;
                t1 = 60
              }
            }, 1000)
          } else {
            this.$message(res.message);
          }
        })
      },
      Yzm1() {
        let t1 = 60
        let obj = {
          "phone": this.form1.newPhone
        }
        this.$ajax('code', 'post', obj).then(res => {
          console.log(res);
          if (res.code == 2000) {
            this.An1 = true
            this.timer1 = setInterval(() => {
              if (t1 > 0) {
                this.codename1 = t1 + "s后重新获取"
                t1--;
                console.log(t1);
              } else {
                this.codename1 = '重新获取'
                this.An1 = false;
                clearInterval(this.timer1);
                this.timer1 = null;
                t1 = 60
              }
            }, 1000)
          } else {
            this.$message(res.message);
          }
        })
      },
      getPage() {
        // 获取项目列表
        this.$newPost('/project/page', {
          "page": this.current0,
          "size": this.size0
        }).then(res => {
          console.log(res)
          this.tableData0 = res.data.records
          this.total0 = res.data.total
        })
      },
      // 点击表格
      handleTb0(item) {

        this.xmcj1 = item.projectName // 面包屑名称
        this.projectId1 = item.id
        // 页码 页数还原
        this.current0 = 1
        this.size0 = 10
        this.getLevel1()
      },
      currentChange0(e) {
        this.current0 = e
        this.getPage()
      },
      sizeChange0(e) {
        // 页码 页数还原
        this.size0 = e
        this.getLevel1()
      },
      edit0(item) {
        this.edit0_show = true
        this.edit_p_obj0 = JSON.parse(JSON.stringify(item))
      },
      delete0(item, id) {
        clearInterval(this.timer)
        this.codename = '获取验证码'
        this.An = false
        this.form.code = ''
        this.delid = item.id
        this.job = id
        this.dialogFormVisible = true
        this.$newGet('/phone/query').then(res => {
          console.log(res, 'xxx')
          if (res.code == 2000) {
            this.form.phone = res.data.phone
          } else {
            this.$message({
              message: res.message,
              type: 'error'
            })
          }
        })
      },
      confirmEdit0() {
        if (this.edit_p_obj0.address == '' || this.edit_p_obj0.projectName == '') {
          this.$message({
            message: '请完整填写数据！',
            type: 'warning'
          })
        } else {
          this.$newPost('/project/update', this.edit_p_obj0).then(res => {
            if (res.code == 2000) {
              this.$message({
                message: res.message,
                type: 'success'
              })
              this.edit0_show = false
              this.getPage()
            } else {
              this.$message({
                message: res.message,
                type: 'error'
              })
              this.edit0_show = false
            }
          })
        }
      },
      confirmAddP() {
        if (this.add_p_obj.address == '' || this.add_p_obj.projectName == '') {
          this.$message({
            message: '请完整填写数据！',
            type: 'warning'
          })
        } else {
          console.log(this.add_p_obj)
          this.$newPost('/project/save', this.add_p_obj).then(res => {
            if (res.code == 2000) {
              this.$message({
                message: res.message,
                type: 'success'
              })
              this.add_p_obj.address = ''
              this.add_p_obj.director = ''
              this.add_p_obj.projectName = ''
              this.add_p_obj.state = 0
              this.show0 = false
              this.getPage()
            } else {
              this.$message({
                message: res.message,
                type: 'error'
              })
              this.show0 = false
            }
          })
        }
      },
      addProject() {
        this.show0 = true
      },
      // 111111111111
      getLevel1() {
        var obj = {
          "page": this.current1,
          "size": this.size1,
          parentId: 0,
          projectId: this.projectId1,
        }
        this.$newPost('/level/page', obj).then(res => {
          if (res.data.records.length > 0) {
            this.tableData1 = res.data.records
            this.total1 = res.data.total
            this.project = 1
          } else {
            this.tableData1 = []
            this.project = 1
            // this.project = 99
            this.projects = 1
          }
        })
      },
      handleTb1(item) {
        console.log(item)
        this.xmcj2 = item.levelName // 面包屑名称
        this.projectId_1 = item.id
        // 页码 页数还原
        this.current1 = 1
        this.size1 = 10
        this.getLevel2()
      },
      currentChange1(e) {
        this.current1 = e
        this.getLevel1()
      },
      sizeChange1(e) {
        // 页码 页数还原
        this.size1 = e
        this.getLevel1()
      },
      edit1(item) {
        console.log(item)
        this.edit1_show = true
        this.edit1_input = item.levelName
        this.cj_id1 = item.id
      },
      delete1(item, id) {
        clearInterval(this.timer)
        this.codename = '获取验证码'
        this.An = false
        this.form.code = ''
        this.delid = item.id
        this.job = id
        this.dialogFormVisible = true
        this.$newGet('/phone/query').then(res => {
          if (res.code == 2000) {
            this.form.phone = res.data.phone
          } else {
            this.$message({
              message: res.message,
              type: 'error'
            })
          }
        })
      },
      confirmEdit1() {
        if (this.edit1_input == '') {
          this.$message({
            message: '请检查填写数据！',
            type: 'warning'
          })
        } else {
          console.log('')
          var obj = {
            id: this.cj_id1,
            levelName: this.edit1_input
          }
          this.$newPost('/level/update', obj).then(res => {
            if (res.code == 2000) {
              this.$message({
                message: res.message,
                type: 'success'
              })
              this.edit1_show = false
              this.getLevel1()
            } else {
              this.$message({
                message: res.message,
                type: 'error'
              })
              this.edit1_show = false
            }
          })
        }
      },
      addLevel1() {
        this.add1_show = true
      },
      confirmAdd1() {
        var obj = {
          "levelName": this.add1_input,
          "parentId": 0,
          "projectId": this.projectId1
        }
        this.$newPost('/level/save', obj).then(res => {
          if (res.code == 2000) {
            this.$message({
              message: res.message,
              type: 'success'
            })
            this.add1_input = ''
            this.add1_show = false
            this.getLevel1()
          } else {
            this.$message({
              message: res.message,
              type: 'error'
            })
            this.add1_show = false
          }
        })
      },
      // 22222222222
      getLevel2(id) {
        var obj = {
          "page": this.current2,
          "size": this.size2,
          parentId: this.projectId_1,
          projectId: this.projectId1,
        }
        this.$newPost('/level/page', obj).then(res => {
          if (res.data.records.length > 0) {
            this.tableData2 = res.data.records
            this.total2 = res.data.total
            this.project = 2
          } else {
            this.tableData2 = []
            this.project = 2
            // this.project = 99
            this.projects = 2
          }
        })
      },
      handleTb2(item) {
        console.log(item)
        this.xmcj3 = item.levelName // 面包屑名称
        this.projectId_2 = item.id
        // 页码 页数还原
        this.current2 = 1
        this.size2 = 10
        this.getLevel3()
      },
      currentChange2(e) {
        this.current2 = e
        this.getLevel2()
      },
      sizeChange2(e) {
        // 页码 页数还原
        this.size2 = e
        this.getLevel2()
      },
      edit2(item) {
        console.log(item)
        this.edit2_show = true
        this.edit2_input = item.levelName
        this.cj_id2 = item.id
      },
      delete2(item, id) {
        // this.cj_id2 = item.id
        // this.$confirm('是否删除次项目?', '提示', {
        //   confirmButtonText: '确定',
        //   cancelButtonText: '取消',
        //   type: 'warning'
        // }).then(() => {
        //   this.$newDelete('/level/delete/' + item.id).then(res => {
        //     console.log(res, 'xxx')
        //     if (res.code == 2000) {
        //       this.$message({
        //         type: 'success',
        //         message: '删除成功!'
        //       });
        //       this.edit2_show = false
        //       this.getLevel2()
        //     } else {
        //       this.$message({
        //         message: res.message,
        //         type: 'error'
        //       })
        //       this.edit2_show = false
        //     }
        //   })
        // })
        clearInterval(this.timer)
        this.codename = '获取验证码'
        this.An = false
        this.form.code = ''
        this.delid = item.id
        this.job = id
        this.dialogFormVisible = true
        this.$newGet('/phone/query').then(res => {
          if (res.code == 2000) {
            this.form.phone = res.data.phone
          } else {
            this.$message({
              message: res.message,
              type: 'error'
            })
          }
        })
      },
      confirmEdit2() {
        if (this.edit2_input == '') {
          this.$message({
            message: '请检查填写数据！',
            type: 'warning'
          })
        } else {
          console.log('')
          var obj = {
            id: this.cj_id2,
            levelName: this.edit2_input
          }
          this.$newPost('/level/update', obj).then(res => {
            if (res.code == 2000) {
              this.$message({
                message: res.message,
                type: 'success'
              })
              this.edit2_show = false
              this.getLevel2()
            } else {
              this.$message({
                message: res.message,
                type: 'error'
              })
              this.edit2_show = false
            }
          })
        }
      },
      addLevel2() {
        this.add2_show = true
      },
      confirmAdd2() {
        var obj = {
          "levelName": this.add2_input,
          "parentId": this.projectId_1,
          "projectId": this.projectId1
        }
        console.log(obj, 'obj');
        this.$newPost('/level/save', obj).then(res => {
          if (res.code == 2000) {
            this.$message({
              message: res.message,
              type: 'success'
            })
            this.add2_input = ''
            this.add2_show = false
            this.getLevel2()
          } else {
            this.$message({
              message: res.message,
              type: 'error'
            })
            this.add2_show = false
          }
        })
      },
      // 33333333333
      getLevel3(id) {
        var obj = {
          "page": this.current3,
          "size": this.size3,
          parentId: this.projectId_2,
          projectId: this.projectId1,
        }
        this.$newPost('/level/page', obj).then(res => {
          if (res.data.records.length > 0) {
            this.tableData3 = res.data.records
            this.total3 = res.data.total
            this.project = 3
          } else {
            this.tableData3 = []
            this.project = 3
            // this.project = 99
            this.projects = 3
          }
        })
      },
      handleTb3(item) {
        console.log(item)
        this.projectId_3 = item.id
        // 页码 页数还原
        return
        this.current3 = 1
        this.size3 = 10
        this.getLevel4()
      },
      currentChange3(e) {
        this.current3 = e
        this.getLevel3()
      },
      sizeChange3(e) {
        // 页码 页数还原
        this.size3 = e
        this.getLevel3()
      },
      edit3(item) {
        console.log(item)
        this.edit3_show = true
        this.edit3_input = item.levelName
        this.cj_id3 = item.id
      },
      delete3(item, id) {
        // this.cj_id3 = item.id
        // this.$confirm('是否删除次项目?', '提示', {
        //   confirmButtonText: '确定',
        //   cancelButtonText: '取消',
        //   type: 'warning'
        // }).then(() => {
        //   this.$newDelete('/level/delete/' + item.id).then(res => {
        //     console.log(res, 'xxx')
        //     if (res.code == 2000) {
        //       this.$message({
        //         type: 'success',
        //         message: '删除成功!'
        //       });
        //       this.edit3_show = false
        //       this.getLevel3()
        //     } else {
        //       this.$message({
        //         message: res.message,
        //         type: 'error'
        //       })
        //       this.edit3_show = false
        //     }
        //   })
        // })
        clearInterval(this.timer)
        this.codename = '获取验证码'
        this.An = false
        this.form.code = ''
        this.delid = item.id
        this.job = id
        this.dialogFormVisible = true
        this.$newGet('/phone/query').then(res => {
          if (res.code == 2000) {
            this.form.phone = res.data.phone
          } else {
            this.$message({
              message: res.message,
              type: 'error'
            })
          }
        })
      },
      confirmEdit3() {
        if (this.edit3_input == '') {
          this.$message({
            message: '请检查填写数据！',
            type: 'warning'
          })
        } else {
          console.log('')
          var obj = {
            id: this.cj_id3,
            levelName: this.edit3_input
          }
          this.$newPost('/level/update', obj).then(res => {
            if (res.code == 2000) {
              this.$message({
                message: res.message,
                type: 'success'
              })
              this.edit3_show = false
              this.getLevel3()
            } else {
              this.$message({
                message: res.message,
                type: 'error'
              })
              this.edit3_show = false
            }
          })
        }
      },
      addLevel3() {
        this.add3_show = true
      },
      confirmAdd3() {
        var obj = {
          "levelName": this.add3_input,
          "parentId": this.projectId_2,
          "projectId": this.projectId1
        }
        this.$newPost('/level/save', obj).then(res => {
          if (res.code == 2000) {
            this.$message({
              message: res.message,
              type: 'success'
            })
            this.add3_input = ''
            this.add3_show = false
            this.getLevel3()
          } else {
            this.$message({
              message: res.message,
              type: 'error'
            })
            this.add3_show = false
          }
        })
      },





      // confirmAddP() {
      //   if (true) {
      //     this.$message({
      //       message: '请完整填写数据！',
      //       type: 'warning'
      //     })
      //   } else {
      //     this.$newPost('/project/save', this.add_p_obj).then(res => {
      //       console.log(res, 'xxx')
      //       if (res.code == 2000) {
      //         this.$message({
      //           message: res.message,
      //           type: 'success'
      //         })
      //         this.show0 = false
      //       } else {
      //         this.$message({
      //           message: res.message,
      //           type: 'error'
      //         })
      //         this.show0 = false
      //       }
      //     })
      //   }
      // },
      // addProject() {
      //   this.show1 = true
      // },
    },
  }
</script>

<style scoped lang="scss">
  .bg1 {
	   background-color: rgba(162, 162, 162, 0.2);
  }

  .bg2 {
    background: rgba(14, 242, 242, 0.25);
  }

  .containerx {
    color: #ffffff;
    overflow: auto;
    background-color: #192534;
    padding: 1rem 0;
  }

  .title {
    font-size: 1.8rem;
    font-weight: 500;
    margin-left: 2rem;
    //margin-top: 2rem;
    height: 5rem;
    line-height: 5rem;
  }

  .add {
    background: #2BC9DE;
    border-radius: 0.5rem;
    width: 6.7rem;
    height: 3.8rem;
    line-height: 3.8rem;
    text-align: center;
    font-size: 0.9rem;
    color: #FFFFFF;
    margin-right: 1rem;
    cursor: pointer;
  }

  .back {
    border: 1px solid #1CB8B8;
    border-radius: 0.5rem;
    width: 6.7rem;
    height: 3.8rem;
    line-height: 3.8rem;
    text-align: center;
    color: rgba(28, 184, 184, 1);
    font-size: 0.9rem;
    margin-right: 2rem;
    cursor: pointer;
  }

  .table {
    width: 100rem;
    height: 34rem;
    overflow: auto;
    margin: 0 auto;

    .tr {
      display: flex;
		padding: .5rem 0;
      // margin-top: 1rem;

      .td1 {
        font-size: 0.9rem;
        width: 6.6rem;
        height: 2rem;
        margin-right: 0.4rem;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .td {
        font-size: 0.9rem;
        height: 2rem;
        display: flex;
        align-items: center;
        padding-left: 2rem;
        margin-right: 0.4rem;
      }

      .td2 {
        width: 83.4rem;
      }

      .td22 {
        width: 20rem;
      }

      .td3s {
        width: 45.8rem;
      }

      .td3 {
        width: 22.7rem;
      }

      .tdd {
        // box-sizing: border-box;
        // border: 1px solid #2BDEC9;
        width: 22.7rem;
        height: 2rem;
        // margin-right: 0.4rem;
        text-align: center;
        line-height: 2rem;
      }

      .tdd2 {
        // box-sizing: border-box;
        // border: 1px solid #2BDEC9;
        width: 15.1rem;
        height: 2rem;
        // margin-right: 0.4rem;
        text-align: center;
        line-height: 2rem;
      }
    }

    .tr:hover {
      background-color: rgba(117, 117, 117, 0.4);
    }
  }

  .pagination {
    display: flex;
    justify-content: flex-end;
    margin-right: 1rem;
  }
</style>
